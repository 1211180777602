<template>
  <div class="page-content">
    <!-- Main Banner -->
    <static-banner @destinationChanged="destinationChanged"/>
    <!-- Page content -->
    <div class="container">
      <!--  Recommendations -->
      <card-slider v-if="recommendationsDataSet.data!==null" title="entdECKungen" :data="recommendationsDataSet.data" card="reiseplan"/>
      <!-- Travel Destination Button -->
      <div class="row">
        <div class="col-12 text-center">
          <router-link style="display:none;"
          class="btn btn-sm btn-home__reise" :to="{ name: 'Destinations'}">Reiseziele auf der Karte entdecken</router-link>
        </div>
      </div>
      <!-- Highlights -->
      <card-slider :data="categories" title="Entdecke lokale Highlights" card="category"/>
      <!-- Upcoming Tours & Tipps -->
      <div class="row">
        <div class="col-12 col-lg-6">
          <card-slider v-if="eventDataSet.data!==null" :data="eventDataSet.data" title="Das ist heute in deiner Nähe los" card="event" />
        </div>
        <div class="col-12 col-lg-4 offset-lg-2">
          <card-slider v-if="institutionDataSet.data!==null" :data="institutionDataSet.data" title="Tipps des Tages" card="location" />
        </div>
      </div>
      <!-- Travel Plans -->
      <card-slider v-if="false" :data="travelPlans" title="Reisepläne der Community" card="reiseplan" />
    </div>

    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { getContentsOfType, getTripmeisterLocations } from '@/api/content';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import { collections4Tripmeister } from '@/api/custom';

  export default {
    name: 'Home',
    components: {
      StaticBanner: () => import('./components/StaticBanner'),
      CardSlider: () => import('@/components/CardSlider')
    },
    data() {
      return {
        loader: null,
        institutionDataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            page:1,
            limit: 2,
            keyword: '',
            type: 'view',
            selectRandomly: true,
            view_status: 1,
            sort: {
              prop: '',
              order: 'asc'
            },
          },
        },
        recommendationsDataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            limit: 6,
            selectRandomly: true,
          },
        },
        eventDataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            page:1,
            limit: 3,
            keyword: '',
            selectedDate: new Date(),
            selectedAppId: this.$appId,
            view_status: 1,
            selectRandomly: true,
            selectedContentTypeId: 2,
            sort: {
              prop: '',
              order: 'asc'
            },
          },
        },
        travelPlans: [
          { image: '/assets/highlights/thumbnails/gastro.jpg', name: 'Surfen in SH', info: '14 Destinationen' },
          { image: '/assets/highlights/thumbnails/kultur.jpg', name: 'Musik und Bars', info: '25 Destinationen' },
          { image: '/assets/highlights/thumbnails/shopping.jpg', name: 'Noch ein Reiseplan', info: 'Kultur, Sightseeing' },
          { image: '/assets/highlights/thumbnails/outdoor.jpg', name: 'Und noch ein Reiseplan', info: 'Kultur, Sightseeing' },
          { image: '/assets/highlights/thumbnails/wellness.jpg', name: 'Und noch ein Reiseplan', info: 'Kultur, Sightseeing' }
          ]
      }
    },
    computed:{
      destinations(){
        return this.$store.getters.getDestinations;
      },
      categories(){
        return this.$store.getters.getCategories;
      },
    },
    created() {
      this.getEventList();
      this.getRecommendationsList();
      this.getInstitutionList();
    },
    methods: {
      showLoader, 
      hideLoader,
      async getInstitutionList() {
        const { limit, page } = this.institutionDataSet.query;
        const { data, meta } = await getTripmeisterLocations(this.institutionDataSet.query);
        this.institutionDataSet.data = data;
        this.institutionDataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
        });
        this.institutionDataSet.meta = meta;
        this.institutionDataSet.total = meta.total;
      },
      async getRecommendationsList() {
        const { limit, page } = this.recommendationsDataSet.query;
        const { data, meta } = await collections4Tripmeister(this.recommendationsDataSet.query);
        this.recommendationsDataSet.data = data;
        this.recommendationsDataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
        });
        this.recommendationsDataSet.meta = meta;
        this.recommendationsDataSet.total = meta.total;
      },
      async getEventList() {
        const { limit, page } = this.eventDataSet.query;
        const { data, meta } = await getContentsOfType(this.$offerId,this.eventDataSet.query);
        this.eventDataSet.data = data;
        this.eventDataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
        });
        this.eventDataSet.meta = meta;
        this.eventDataSet.total = meta.total;
      },
      destinationChanged(destination){
        this.$store.commit('SET_SELECTED_CATEGORIES', []);
        this.$router.push({ name: 'Destinations', params: {target: "locations" } });
      },
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

 .card-news {
  min-height: 300px !important;
 }

</style>